<template>
  <div style="height:100%;">
    <index path="/log/operate"></index>
    <div style="position: absolute;right: 1%;width: 82%;height:100%;">
      <div style="height: 50px;padding: 10px;">
        <div style="float: left;width: 17%;margin-left: 1%;margin-top: 8px;color: #808080;">
          <span>历史操作日志（{{ logList.length }}条/共{{ count }}条）</span>
        </div>
        <div class="tools-bar" style="float: right;">
          <el-select v-model="operate" placeholder="操作端" size="mini" style="width:9vw; padding-right: 0.7vw" clearable @change="selectChange">
            <el-option
                v-for="item in operateList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
            >
            </el-option>
          </el-select>
          <el-select v-model="type" placeholder="操作类型" size="mini" style="width:9vw; padding-right: 0.7vw" clearable @change="selectChange">
            <el-option
                v-for="item in operateTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
            >
            </el-option>
          </el-select>
          <el-date-picker
              size="mini"
              style="width: 15vw"
              v-model="date"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="defaultTime">
          </el-date-picker>
          <el-button style="margin-left: 0.7vw;" size="mini" type="primary" icon="el-icon-search" @click="search">搜索</el-button>

        </div>
      </div>
      <list style="height: 82.8vh;" :log_list="logList" :sortthiscolumn="sortthiscolumn"></list>
      <div class="bottom" style="float: right;margin-right: 35px;margin-top: 1vh;">
        <div style="float: left;margin-left: 35px;margin-top: 10px;margin-right: 35px;">
          <el-button size="mini" type="primary" icon="el-icon-download" @click="exportOperateLog" >导出</el-button>
        </div>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="count">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import index from '../index'
import list from './list'
import { getOperateLogList } from '@/api/log'

export default {
  components: {
    index,
    list
  },
  computed: {
    defaultTime() {
      var time = new Date();
      var hour = time.getHours();
      var minute = time.getMinutes();
      var second = time.getSeconds();
      let defaultTime = []
      defaultTime[0] = '00:00:00'
      defaultTime[1] = hour + ':' + minute + ':' + second
      return defaultTime;
    }
  },
  data() {
    return {
      operateList:[{id:1,name:'服务端'},{id:2,name:'微信端'}],
      operateTypeList:[{id:1,name:'账号'},{id:2,name:'服务商'},{id:3,name:'终端用户（企业）'},{id:4,name:'终端用户账号'},{id:5,name:'设备'}],
      operate:null,
      type:null,
      date: [],
      count:0,
      logList:[],
      list:[],
      pageNo:1,
      pageSize:10,
      column:{prop: 'create_time', order: 'descending'},
    }
  },
  mounted() {
    this.search();
  },
  watch:{
    operate(){
        if (this.operate == '微信端') {
          this.operateTypeList = [{id:1,name:'用户'},{id:2,name:'设备'},{id:3,name:'其他'}]
        }
        if (this.operate == '服务端') {
          this.operateTypeList = [{id:1,name:'账号'},{id:2,name:'服务商'},{id:3,name:'终端用户（企业）'},{id:4,name:'终端用户账号'},{id:5,name:'设备'}]
        }
      }
  },
  methods: {
    selectChange(){
      this.search()
    },
    //排序方式
    sortthiscolumn(column) {
      this.column = column
      this.search();
    },
    search(){
      this.pageNo = 1
      this.init()
    },
    handleCurrentChange(pageNo){
      this.pageNo = pageNo
      this.init()
    },
    handleSizeChange(size){
      this.setPageSizeIndex(size)
      this.search()
    },
    init(){
      this.pageSize=this.pageSizeIndex
      var list
      let begin = this.date && this.date.length > 1 && this.date[0] ? new Date(+new Date(this.date[0]) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') : null;
      let end = this.date && this.date.length > 1 && this.date[1] ? new Date(+new Date(this.date[1]) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') : null;
      getOperateLogList({
        timeBegin:begin,
        timeEnd:end,
        pageNo:this.pageNo,
        pageSize:this.pageSize,
        operate:this.operate,
        type:this.type,
        prop:this.column.prop,
        order:this.column.order,
      }).then(res => {
        list = res.data.data.list
        this.count=res.data.data.totalRow
        this.logList = list
      })
    },
    exportOperateLog(){
      if ((this.date && this.date.length > 1 && this.date[0]) || (this.date && this.date.length > 1 && this.date[1])) {

      }else{
        this.$message.warning("请选择开始时间和结束时间")
        return
      }
      let begin = this.date && this.date.length > 1 && this.date[0] ? new Date(+new Date(this.date[0]) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') : null;
      let end = this.date && this.date.length > 1 && this.date[1] ? new Date(+new Date(this.date[1]) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') : null;
      let url = "/api/log/exportOperateLog"
      let flag = 0;
      if(begin){
        flag++
        url+="?timeBegin="+begin
      }
      if(end){
        if(flag == 0){
          url+="?"
        }else{
          url+="&"
        }
        url+="timeEnd="+end
        flag++
      }
      if(this.operate){
        if(flag == 0){
          url+="?"
        }else{
          url+="&"
        }
        url+="operate="+this.operate
        flag++
      }
      if(this.type){
        if(flag == 0){
          url+="?"
        }else{
          url+="&"
        }
        url+="type="+this.type
        flag++
      }
      if(this.column.prop){
        if(flag == 0){
          url+="?"
        }else{
          url+="&"
        }
        url+="prop="+this.column.prop
        flag++
      }
      if(this.column.order){
        if(flag == 0){
          url+="?"
        }else{
          url+="&"
        }
        url+="order="+this.column.order
        flag++
      }
      window.location.href = url
    },
  }
}
</script>

<style lang="scss" scoped>
.bottom{
  position: absolute;
  width: 98%;
  height: 50px;
  bottom: 0;
  margin-left: 1%;
  box-shadow: 0px -3px 3px #c4c4c4;
  .el-pagination{
    float: right;
    margin-top: 5px;
    margin-right:5px;
  }
}
</style>