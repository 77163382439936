<template>
  <div style="clear:both" class="scroll-container">
    <el-table
        :data="log_list"
        ref="table"
        border
        show-header
        highlight-current-row
        size="mini"
        @sort-change='sortthiscolumn'
        :default-sort = "{prop: 'enable', order: 'descending'}"
    >

      <el-table-column
          align="center"
          prop="name"
          sortable='name'
          label="操作账号"
          show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
          align="center"
          prop="channel"
          label="操作途径"
          sortable='channel'
          show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
          prop="content"
          sortable='content'
          label="日志内容"
          align="center"
          show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
          prop="record_id"
          sortable='record_id'
          label="增改ID"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="create_time"
          sortable='create_time'
          label="操作时间"
          align="center"
          show-overflow-tooltip
      ></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props:{
    log_list: Array,
    sortthiscolumn: Function,
  }
}
</script>

<style scoped>
.scroll-container {
  height: calc(100% - 30px);
  overflow: auto;
}
</style>